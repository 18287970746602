import {
    combineReducers,
    configureStore,
    createImmutableStateInvariantMiddleware,
  } from '@reduxjs/toolkit';
  import financeReducer from './reducers/financeSlice';
  import tabReducer from './reducers/tabSlice';
  
  const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
    ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
  });
  
  const rootReducer = combineReducers({
    tab : tabReducer,
    finance :financeReducer,
  });
  
  const store: any = configureStore({
    reducer: rootReducer,
    // Note that this will replace all default middleware
    middleware: [immutableInvariantMiddleware],
  });
  
  export type RootState = ReturnType<typeof store.getState>
  
  export default store;
  