import React, {useState} from "react";
import {  Button,notification,Modal } from "antd";
import { useSelector } from 'react-redux';
import { ArrowLeftOutlined} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from '../helpers/LocalStorage';
import { createDiscountsApi } from '../services/invoices';
import { convertFloatDotSeperated } from "../helpers/common";
import Denizbank from '../assets/bankLogo/Denizbank.png';
// import Akbank from '../assets/bankLogo/Akbank.png';

const Offers = () => {
  const navigate = useNavigate();
  const [applicationNumber, setApplicationNumber] = useState('');
  const [loadingx, setLoadingx] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {financialInstitutions,invoices} = useSelector((state: any) => state.finance);

  const handleClickBack = () => {
    navigate('/invoices-application'); 
    LocalStorageService.setTabKey('1')
  };

  const handleClick = async (financialInstitutionId:any,financialInstitutionName:any) => {
      setLoadingx(true)
      const newData = {
         buyer: {
            id: invoices[0].buyerId,
            title: invoices[0].buyerTitle,
            taxId: invoices[0].buyerTaxNumber,
         },
         averageMaturity: financialInstitutions[0].averageMaturity,
         invoices: invoices.map((item: any) => {
            return (
               {
                  id: item.id,
                  date: item.invoiceDate,
                  fileName: item.fileName,
                  number: item.invoiceNumber,
                  hashCode: item.hashCode,
                  total: item.invoiceTotal,
                  currency: item.currency,
                  invoiceType: item.invoiceType,
                  term: item.invoiceTerm,
                  isApproved: false,
                  createdDate: item.createdDate
               }
            )
         }),
         financialInstitutions: {
            id:financialInstitutionId,
            name:financialInstitutionName
         }
      };

      if (newData && Object.keys(newData).length > 0) {
         try {
            const response: any = await createDiscountsApi(newData);
            setApplicationNumber(response.applicationNumber);
            setIsModalOpen(true);
            setLoadingx(false)
            // setTimeout(() => {
            //    setIsModalOpen(false);
            //    window.location.reload(); 
            //  }, 8000); 
         } catch (e: any) {
            console.log(e);
            setLoadingx(false)
            if (e.data.Status === 406 && e.data.Type.includes('NotEnoughAgriculturistLimitException')) {
               notification.error({ message: 'Çiftçi limitiniz yetersiz.Lütfen alıcınızla iletişime geçin.' });
            }
            if (e.data.Status === 406 && e.data.Type.includes('NotEnoughBuyerLimitException')) {
               notification.error({ message: 'Alıcınızın limiti yetersiz. Lütfen alıcınızla irtibata geçin.' });
            }
            if (e.data.Status === 403 && e.data.Type.includes('InterestRatesExpiredException')) {
              notification.error({ message: 'Finansal Kurumun faiz oranları sona erdi.' });
           }
         }
      } 
   };
  
  // const getLogo = (vkn, name) => {
  //   let content;
  //   if (vkn=== '0150015264') {
  //       content = <img src={akbank} alt="akbank" width="160px" /> 
  //     } 
  //     else if(vkn=== '2920084496'){
  //       content = <img src={denizbank} alt="akbank" width="160px" /> 
  //     }else if(vkn=== '2651554884'){
  //       content = <img src={dyatirimbank} alt="akbank" width="160px" /> 
  //     }else if(vkn=== '2910141668'){
  //       content = <img src={denizfactoring} alt="akbank" width="160px" /> 
  //     } else{
  //       content = <h3>{name}</h3> 
  //     }
  //     return content
  // }

  const handleCancel = () => {
    setIsModalOpen(false);
    navigate('/invoices-application'); 
    LocalStorageService.setTabKey('2')
 };
  
  return (
    <>
    <div className="routing-space" >
      <Button
        type="primary"
        icon={<ArrowLeftOutlined />}
        onClick={handleClickBack}
      >
          Faturalarıma Geri Dön
      </Button>
    </div>

   
    <h3 className='noti-row'>Teklifler:</h3>
     <div className="offers-list">
      {
        financialInstitutions.length < 1 ?
        <p>Bir hata oluştu Faturalarıma geri dönünüz.</p>
        :
        financialInstitutions && financialInstitutions?.map((item:any) => {
          return(
            <div className="table-row" key={item.id}>
              <div className='table-div-first'>
                <div className="table-col">
                  {
                    item.financialInstitutionName  ? 
                    <img src={Denizbank} alt="denizbank" width="160px" /> 
                    :
                    item.financialInstitutionName
                  }
                
                </div>
                {/* <div className="table-col mobilt">
                    <span className="title">Mevcut Limit:</span>
                    <span>100 TL</span>
                </div>
                <div className="table-col mobilt">
                    <span className="title">Mevcut Risk:</span>
                    <span>100 TL</span>
                </div> */}
                <div className='table-div'>
                  <div className="table-col mobilt">
                      <span className="title">Teklif Tutarı:</span>
                      <span>{convertFloatDotSeperated(item.calculatedSumAmount)} TL</span>
                  </div>
                  {/* <div className="table-col mobilt">
                      <span className="title">Kalacak Limit:</span>
                      <span>-</span>
                  </div> */}
                  <div className="table-col">
                      <Button  className="apply-box btn-color" 
                        onClick={() => handleClick(item.financialInstitutionId, item.financialInstitutionName)} 
                        loading={loadingx}
                        >Hemen Başvur</Button>
                  </div>
                </div>
              </div>
          </div>
          )
        })
      }
     </div>
     <Modal
        closable={false}
        open={isModalOpen}
        footer={[
          <Button key="ok" type="primary" onClick={handleCancel}>
            Kapat
          </Button>
          ]}
         >
            <p>{applicationNumber} numaralı başvurunuz başarıyla oluşturuldu. Başvurunuzun durumunu "Başvurularım" sekmesinden takip edebilirsiniz.</p>
      </Modal>
      
    </>
  );
};

export default Offers;
