import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Modal, Form, Input, notification } from "antd";
import { HourglassOutlined, CheckCircleOutlined, CloseCircleOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { convertFloatDotSeperated, applicationStatusMapByValue } from "../helpers/common";
import { getDiscountsDetailApi, discountsAcceptApi, sendDelivered } from '../services/invoices';
import HeaderUser from "../components/HeaderUser";
import { LocalStorageService } from "../helpers/LocalStorage";
import {LoadPdfTemlik} from  '../helpers/file';

const ApplicationDetail = () => {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname.split('/');
  const discountId = url[2];
  const [loading, setLoading] = useState<any>(false);
  const [detailData, setDetailData] = useState<any>([]);
  const [show, setShow] = useState<any>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [group, setGroup] = useState(true);

  const statusOutput = (value: any) => {
    if (value === 'ON_ODEME_YAPILDI') {
      return <h4 style={{ color: '#62b61f' }}>Ön Ödeme Yapıldı</h4>
    }
    if (value === 'ODENDI') {
      return <h4 style={{ color: '#40a9ff' }}>Ödendi</h4>
    }
    if (value === 'SECILDI') {
      return <CheckCircleOutlined style={{ color: '#62b61f', fontSize: 22 }} />
    }
    if (value === 'SECIM_YAPILMADI') {
      return <HourglassOutlined style={{ color: '#f5ee12', fontSize: 22 }} />
    }
    if (value === 'IPTAL_EDILDI') {
      return <CloseCircleOutlined style={{ color: '#cc2c2c', fontSize: 22 }} />
    }
    if (value === '') {
      return ''
    }
    if (value !== 'ON_ODEME_YAPILDI' && value !== 'SECILDI' && value !== 'SECIM_YAPILMADI' && value !== 'ODENDI') {
      return <CloseCircleOutlined style={{ color: '#cc2c2c', fontSize: 22 }} />
    }

  }

  const getDiscountsDetail = async () => {
    try {
      setLoading(true)
      const response: any = await getDiscountsDetailApi(discountId);
      if (response) {
        setDetailData(response);
        // setOfferBtnShow(response.offer !== null && response.status !== "CEVAP_GELDI")
        setLoading(false);
      }
    } catch (e: any) {
      console.log(e)
    }
  }

  const handleClick = async () => {
    try{
      await discountsAcceptApi(discountId,detailData.financialInstitutionId);
      getDiscountsDetail();
    }catch(e:any){
      console.log(e)
    }
  }

  const handleClickBack = () => {
    navigate('/invoices-application'); 
    LocalStorageService.setTabKey('2')
  };

  const handleClickDownload = async () => {
    setShow(true);
    await LoadPdfTemlik(detailData, 'indir', '');  
  }

  const handleSendContract =  () => {
    setShow(true);
    setIsModalVisible(true);  
  }

  const onFinish = async (values:any) => {
    if(values){
      setLoading(true);
      try{
        await LoadPdfTemlik(detailData, 'email',  values.email); 
        setLoading(false);
        setIsModalVisible(false);  
        form.resetFields();
        notification.success({
          message: `Temlikname, ${values.email} adresine gönderilmiştir.`,
        });
      }catch(e:any){
        console.log(e);
        form.resetFields();
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleDeliverSend = () => {
    setIsModalOpen(true)
  }

  const handleDeliver = async () => {
    setLoading(true)
    try {
      await sendDelivered(discountId);
      getDiscountsDetail();
      setLoading(false);
      setIsModalOpen(false);
    } catch (e: any) {
      console.log(e)
    }

  }

  const handleDeliverCancel = () => {
    setIsModalOpen(false);
    setGroup(true);
  }

  useEffect(() => {
    getDiscountsDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [discountId])

  const columns: any = [
    {
      title: <>Fatura <br /> No</>,
      dataIndex: 'invoiceNumber',
      key: "invoiceNumber",
      fixed: 'left',
      width: '130px'
    },
    {
      title: <>Tutar <br /> (TL)</>,
      dataIndex: 'invoiceTotal',
      key: "invoiceTotal",
      width: '100px',
      render: convertFloatDotSeperated,
    },
    {
      title: <>Fatura <br /> Vadesi</>,
      dataIndex: 'invoiceTerm',
      key: "invoiceTerm",
      width: '80px',
      render: (date: any) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: <>Onay <br /> Durumu</>,
      dataIndex: 'status',
      key: "status",
      render: (value: any) => statusOutput(value)
    },


  ];
  const userName = LocalStorageService.getUserName();
  const userText = `${detailData.number} no'lu başvuruya ilişkin güncel durumu aşağıdan izleyebilirsiniz.`

  return (
    <>
      <div className="routing-space">
        <Button
          type="primary"
          icon={<ArrowLeftOutlined />}
          onClick={handleClickBack}
        >
          Başvurularıma Geri Dön
        </Button>
      </div>
      <HeaderUser userName={userName} userText={userText} />
      <div className="application-detail-table">
        <ul>
          <li>
            <span>Başvuru No</span>: {detailData.number}
          </li>
          <li>
            <span>Başvuru Tarihi</span>: {moment(detailData.date).format('DD-MM-YYYY')}
          </li>
          <li>
            <span>Durumu</span>: {applicationStatusMapByValue[detailData.status]?.text}
          </li>
          <li>
            <span>Toplam Fatura Tutarı</span>: {convertFloatDotSeperated(detailData.invoicesTotal)} TL
          </li>
          <li>
            <span>Toplam Fatura Adedi</span>:  {detailData.invoicesCount} Adet
          </li>
        </ul>
      </div>
      <div>
        <Table
          className="w-100 invoiceTable"
          pagination={{
            position: ["topRight", "none" as any],
            showLessItems: true,
            showSizeChanger: true,
            // current: pageDis,
            // pageSize: psizeDis,
            // total: totalDataCountDis,
            // onChange: handleShowSizeDis,
          }}
          loading={loading}
          columns={columns}
          dataSource={detailData.invoices}
          scroll={{
            x: 450,
          }}
        />
        {
          detailData.offer !== null && detailData.status !== "TEKLIFI_ONAYLADIM" && detailData.status !== "TEMLIKNAME_YUKLENDI" &&
          (
            <div className='amount-received' style={{marginTop:'30px'}}>
              <div className='amount-received-txt' >
                <Text className='amount-received-t1'>Elinize Geçecek Tutar:</Text>
                <Text className='amount-received-t2'>{detailData.offer !== null || detailData.offer !== '' ? `${convertFloatDotSeperated(detailData.offer)} TL` : ''}</Text>
              </div>
              <Button onClick={handleClick} className='amount-received-link'>Teklifi Kabul Et</Button>
            </div>
          )
        }

        {
          detailData.offer !== null && detailData.status === "TEKLIFI_ONAYLADIM" && group  &&
          (
            <div className="amount-received" style={{border:'none'}}>
              <Button onClick={handleClickDownload} className='amount-received-link app-btn'>Kredi Sözleşmesini İndir</Button>
               veya <br/> 
              <Button onClick={handleSendContract} className='amount-received-link app-btn'>Kredi Sözleşmesini E-Posta Olarak Gönder</Button>

              { show &&  <Button onClick={handleDeliverSend} className="second-btn">Kredi Sözleşmesini Şubeye Teslim Ettim</Button>} 
            </div>
           
          )
        }
      </div>
      <Modal
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Form
          form={form}
          name="emailForm"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Emailinizi giriniz."
            name="email"
            rules={[{ required: true, message: 'Lütfen e-postanızı girin!' }, { type: 'email', message: 'Lütfen geçerli bir e-posta girin!' }]}
          >
            <Input placeholder="Email adresiniz" />
          </Form.Item>
          <Form.Item style={{ textAlign:'right', marginBottom:'0'}}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Gönder
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={isModalOpen}
        onCancel={handleDeliverCancel}
        maskClosable={false}
        footer={[
          <Button key="ok" type="primary" onClick={handleDeliver}>
            Kabul Et
          </Button>,
           <Button key="pass" type="default" onClick={handleDeliverCancel}>
            Reddet
         </Button>,
        ]}
      >
       <p>
       İmzalı kredi sözleşmesini Denizbank şubesine teslim ettiğinizi beyan ediyorsunuz. Kabul etmeniz durumunda işleminize devam edilecektir.
       </p>
      </Modal>
    </>
  );
};

export default ApplicationDetail;
