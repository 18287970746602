/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface FinanceState {
  financialInstitutions : any,
  invoices : any
}

const initialState = {
  financialInstitutions: [],
} as FinanceState;

const financeSlice = createSlice({ 
  name: 'finance',
  initialState,
  reducers: {
    setFinancialInstitutionsData(state, action) {
      state.financialInstitutions = action.payload;
    },
    setInvoicesData(state, action) {
      state.invoices = action.payload;
    },
  },
});

export const { setFinancialInstitutionsData,setInvoicesData } = financeSlice.actions;
export default financeSlice.reducer;
