import React from "react";
import { Typography} from "antd";
import { useNavigate,Link } from "react-router-dom";
// import BackIcon from '../assets/arrow-go-back.svg'
import { LocalStorageService } from '../helpers/LocalStorage';

const Header = () => {
  const { Text } = Typography;
  let navigate = useNavigate();
  const platformCode = LocalStorageService.getPlatformCode() === '2';
  const token = LocalStorageService.getAuthToken();

  const onClickLogout = async () => {
      LocalStorageService.removeAuthToken();
      LocalStorageService.removePlatformCode();
      LocalStorageService.removeUserName();
      navigate("/login"); 
  };

  return (
    <header>
      <div className={ !platformCode ? 'header-div header-div-stil' : 'header-div'} >

      {/* {
          platformCode  ? (
            <Link to='' className='header-back-link'>
            <img src={BackIcon} width="20" height="20" style={{ marginTop: '4px' }} alt="icon" />
            <Text>Deniz'den Toprağa Geri Dön</Text>
          </Link>
          ) : ''
      } */}
      { token && !platformCode ? (
          <Link to="" className="logout-link">
          <div onClick={onClickLogout}>
            <Text style={{ color: '#fff', fontSize:'16px' }} >
              Çıkış
            </Text>
          </div>
        </Link>
        ): ''
      }
      

      </div>
    </header>
  );
}

export default Header;